import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBedFront, faTableCellsLarge, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { getImageFromApiUrl } from 'utils/http';
import { formatAsCurrency } from 'utils/currency';
import { createCompanyLink, createPropertyGroupLink, createPropertyLink } from 'utils/links';
import { useRouter } from 'next/router';
import { LanguageKey } from 'types/common';
import { format, parseISO } from 'date-fns';
import PropertyImageThumbnail from 'components/PropertyImageThumbnail';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import { useTranslation } from 'next-i18next';
import { PROPERTY_TYPE_APARTMENT, PROPERTY_TYPE_HOUSE, PROPERTY_TYPE_TOWNHOUSE } from 'utils/constants';
import { AvailabilityStatus, PublicPropertyDTO } from '../../schemas/properties/PublicPropertyDTO';

type SearchPagePropertyCardProps = {
  property: PublicPropertyDTO;
};

const formatAvailableFrom = (availableFrom: string | undefined | null, nowTranslation: string | undefined | null) => {
  return availableFrom ? (parseISO(availableFrom) < new Date() ? nowTranslation : `${format(parseISO(availableFrom), 'dd-MM-Y')}`) : '-';
};

//Experimenting with property card styles on the serach page without breaking landing pages
const SearchPagePropertyCard = ({ property }: SearchPagePropertyCardProps) => {
  const {
    id,
    name,
    city,
    postalcode,
    size,
    rooms,
    availableFrom,
    price,
    image,
    unit,
    companyId,
    propertyGroupId,
    companyName,
    propertyGroupName,
    availabilityStatus,
    propertyType,
    propertySlug,
    propertyGroupSlug,
    companySlug,
  } = property;
  const { locale } = useRouter();
  const { theme } = useWhiteLabel();
  const { t } = useTranslation('general');

  const getTransactionStatusPill = (availabilityStatus: AvailabilityStatus) => {
    if (availabilityStatus === 'Available') {
      return (
        <span
          style={{ backgroundColor: theme.color.availableCardPillBackgroundColor, color: theme.color.availableCardPillTextColor }}
          className="absolute left-6 top-6 rounded-[70px] px-4 py-1 text-xs font-semibold leading-tight text-white"
        >
          {t('available')}
        </span>
      );
    }

    return (
      <span
        style={{ backgroundColor: theme.color.reservedCardPillBackgroundColor, color: theme.color.reservedCardPillTextColor }}
        className="absolute left-6 top-6 rounded-[70px] px-4 py-1 font-['Poppins'] text-xs font-semibold leading-tight text-white"
      >
        {t('reserved')}
      </span>
    );
  };

  const getPropertyType = (propertyType: number | undefined | null) => {
    if (propertyType === PROPERTY_TYPE_APARTMENT) {
      return t('apartment');
    }
    if (propertyType === PROPERTY_TYPE_TOWNHOUSE) {
      return t('townhouse');
    }
    if (propertyType === PROPERTY_TYPE_HOUSE) {
      return t('house');
    }
    return '-';
  };

  return (
    <div className="relative flex h-[420px] w-full flex-col rounded-lg border border-solid border-gray-200 bg-white xs:max-w-[260px]">
      <div className="relative h-[55%] w-full overflow-hidden rounded-t-lg">
        <div className="absolute h-full w-full">
          <Link href={createPropertyLink(id, name, propertySlug, locale as LanguageKey)} passHref>
            <a>
              <PropertyImageThumbnail
                src={getImageFromApiUrl(String(image?.name), 384, true)}
                alt={name}
                backgroundColor={theme.color.propertyCardBackgroundColor}
                logoColor={theme.color.propertyCardLogoColor}
              />
            </a>
          </Link>
        </div>
      </div>
      <div className="flex w-full flex-col px-5 pb-4 pt-3">
        <Link href={createPropertyLink(id, name, propertySlug, locale as LanguageKey)} passHref>
          <a className="mb-2 truncate text-sm font-semibold text-neutral-800">{name}</a>
        </Link>
        <div className="mb-2 flex items-center text-[13px] leading-5">
          <FontAwesomeIcon icon={faTableCellsLarge} className="mr-2 h-3" />
          <span className="mr-3 font-normal text-slate-800">{size || '-'} m²</span>
          <FontAwesomeIcon className="mr-2 h-3" icon={faBedFront} />
          <span className="mr-3 font-normal text-slate-800">{rooms || '-'}</span>
          <FontAwesomeIcon className="mr-2 h-3" icon={faCalendar} />
          <span className="font-normal text-slate-800">{formatAvailableFrom(availableFrom, t('now'))}</span>
        </div>
        <span className="mb-2 truncate text-sm text-slate-800">
          {postalcode} {city}
        </span>
        <Link href={createPropertyGroupLink(propertyGroupId, propertyGroupName, propertyGroupSlug, locale as LanguageKey)} passHref>
          <a className="mb-3 truncate text-sm text-slate-800 underline visited:text-slate-800">{propertyGroupName}</a>
        </Link>
        <span className="mb-5 text-sm text-slate-800 visited:text-slate-800">{getPropertyType(propertyType)}</span>
        <span className="mb-2 text-lg font-bold text-slate-800">{formatAsCurrency(Number(price), String(unit))}</span>
        <Link href={createCompanyLink(companyId, companyName, companySlug, locale as LanguageKey)} passHref>
          <a className="truncate text-[10px] leading-4 text-slate-800 visited:text-slate-800">{companyName}</a>
        </Link>
      </div>
      {getTransactionStatusPill(availabilityStatus)}
    </div>
  );
};

export default SearchPagePropertyCard;
