import LoadingIcon from 'components/LoadingIcon';
import { usePropertiesSearch } from 'hooks/usePropertiesSearch';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import { useMemo } from 'react';
import SearchPagePropertiesGrid, { SearchPagePropertiesGridProps } from './SearchPagePropertiesGrid';
import SavedSearchBox from 'components/SavedSearch/SavedSearchBox';
import { ParsedUrlQuery } from 'querystring';
import { useTranslation } from 'next-i18next';

type PropertiesGridWrapperProps = {
  query: ParsedUrlQuery;
  saveSearch: () => Promise<void>;
  savedSearches: any[];
  justSaved: boolean;
  pageData: any;
  generalData: any;
  setTotalProperties: (count: number) => void;
  pageSize: number;
  page: number;
} & Omit<SearchPagePropertiesGridProps, 'data' | 'pagination'>;

const PropertiesGridWrapper = ({
  query,
  page,
  pageSize = 20,
  setTotalProperties,
  saveSearch,
  savedSearches,
  justSaved,
  pageData,
  generalData,
}: PropertiesGridWrapperProps) => {
  const { companyIds } = useWhiteLabel();
  const updatedQuery = useMemo(
    () => ({ ...query, ownerTypeAndIds: [...(query?.ownerTypeAndIds || []), ...companyIds?.map((id) => `c.${id}`)] }),
    [query, companyIds],
  );
  const { result, loading } = usePropertiesSearch(updatedQuery, page, pageSize);
  const { t } = useTranslation('search');

  if (loading || !result) {
    return (
      <div className="mb-14 flex min-h-screen justify-center md:justify-start">
        <div className="relative grid w-full grid-cols-1 gap-7 xs:max-w-[640px] xs:grid-cols-auto-fill-260 mdlg:max-w-[1000px] 2xl:max-w-[1200px] [@media(min-width:1760px)]:max-w-[1600px] [@media(min-width:2100px)]:max-w-[1700px]">
          <div className="absolute left-1/2 top-[67px] -translate-x-1/2 -translate-y-1/2 transform">
            <LoadingIcon />
          </div>
          <div className="relative flex h-[420px] w-full flex-col xs:max-w-[260px]" />
        </div>
      </div>
    );
  }

  if (result.pagination.total === 0) {
    return (
      <>
        <SavedSearchBox
          query={query}
          saveSearch={saveSearch}
          savedSearches={savedSearches}
          justSaved={justSaved}
          fromCMS={pageData}
          generalTexts={generalData}
        />
        <div className="mb-14 flex min-h-screen justify-center md:justify-start">
          <div className="relative grid w-full grid-cols-1 gap-7 xs:max-w-[640px] xs:grid-cols-auto-fill-260 mdlg:max-w-[1000px] 2xl:max-w-[1200px] [@media(min-width:1760px)]:max-w-[1600px] [@media(min-width:2100px)]:max-w-[1700px]">
            <div className="absolute left-1/2 top-[67px] flex w-[300px] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center text-center md:w-[600px]">
              <h3 className="mb-[18px] text-base font-semibold md:text-xl">{t('noPropertiesFound')}</h3>
              <span className="text-base md:text-base">{t('tryAdjustingSearch')}</span>
            </div>
            <div className="relative flex h-[420px] w-full flex-col xs:max-w-[260px]" />
          </div>
        </div>
      </>
    );
  }

  const { data, pagination } = result;

  setTotalProperties(pagination.total || 0);

  return (
    <>
      <SavedSearchBox
        query={query}
        saveSearch={saveSearch}
        savedSearches={savedSearches}
        justSaved={justSaved}
        fromCMS={pageData}
        generalTexts={generalData}
      />
      <SearchPagePropertiesGrid pagination={pagination} data={data} isClientSide={true} />
    </>
  );
};

export default PropertiesGridWrapper;
