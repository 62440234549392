import { z } from 'zod';
import { apiPaginatedResponseSchema } from '../api-response/apiResponse';
import { optionalWithNull } from '../generic/optional-with-null';
export const availabilityStatusSchema = z.enum(['Available', 'Reserved', 'Rented']);
export type AvailabilityStatus = z.infer<typeof availabilityStatusSchema>;

export const embeddedImageSchema = z.object({
  name: z.string(),
  description: optionalWithNull(z.string()),
  index: z.number(),
  addedOn: optionalWithNull(z.string()),
  updatedOn: optionalWithNull(z.string()),
});
export type EmbeddedImage = z.infer<typeof embeddedImageSchema>;

export const publicPropertyDTOSchema = z.object({
  id: z.string(),
  propertyGroupId: z.string(),
  companyId: z.string(),
  propertyGroupName: z.string(),
  companyName: z.string(),
  name: z.string(),
  rooms: z.number().nullable().optional(),
  size: z.number().nullable().optional(),
  availableFrom: optionalWithNull(z.string()), // or z.string().nullable().optional() if using ISO only
  availabilityStatus: availabilityStatusSchema,
  image: embeddedImageSchema.nullable().optional(),
  price: z.number().nullable().optional(),
  city: z.string().nullable().optional(),
  postalcode: z.string().nullable().optional(),
  propertyType: z.number().nullable().optional(),
  unit: z.string().nullable().optional(),
  propertyGroupSlug: z.string(),
  companySlug: z.string(),
  propertySlug: z.string(),
});
export type PublicPropertyDTO = z.infer<typeof publicPropertyDTOSchema>;
export const findPublicPropertiesResponseSchema = apiPaginatedResponseSchema(publicPropertyDTOSchema);
export type FindPublicPropertiesResponse = z.infer<typeof findPublicPropertiesResponseSchema>;
